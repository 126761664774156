import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <header >
            <nav class="relative bg-gray-900  py-1">
                <div class="flex items-center justify-center mx-2">

                    <Link to="/" className="my-2 text-gray-200 text-sm mx-4 lg:text-xl sm:text-lg hover:text-green-500 dark:hover:text-green-400 md:mx-4 md:my-0" style={{ fontFamily: 'STIX Two Math, sans-serif' }}>INICIO</Link>
                    <Link to="/cann" className="my-2 text-gray-200 text-sm mx-4 lg:text-xl sm:text-xl hover:text-green-500 dark:hover:text-green-400 md:mx-4 md:my-0" style={{ fontFamily: 'STIX Two Math, sans-serif' }}>CANNABINOIDES</Link>
                    <Link to="/" class="my-2 text-gray-200 text-sm sm:text-lg lg:text-xl hover:text-green-500 dark:hover:text-green-400 md:mx-4 md:my-0 "  style={{ fontFamily: 'STIX Two Math, sans-serif' }}>
                        <span>
                            <img src="assets/nav.png" className="w-50 h-10 my-0" alt="Logo de la marca" />

                        </span>
                    </Link>
                    <Link to="/products" class="text-gray-200 ml-3 text-sm  sm:text-lg  lg:text-xl hover:text-green-500 dark:hover:text-green-400 " style={{ fontFamily: 'STIX Two Math, sans-serif' }}>PRODUCTOS</Link>





                </div>






            </nav>

        </header>

    );
};

export default Navbar;
