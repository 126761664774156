import React from 'react';
import ProductSection from './ProductSection'

function Products() {
    
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };
    return (
        <div className='bg-black'>
            <section className="relative">
                <div class="relative pt-40 pb-40 flex content-center items-center justify-end min-h-screen-75">

                    <div className="absolute top-0 w-full h-full bg-center bg-cover" style={{ backgroundImage: `url('/assets/pag-3.png')` }}>
                    </div>
                </div>
                <div className="absolute inset-0 mt-40 -mb-20 flex items-center justify-center">
                    < button className='bg-white rounded-lg px-10 mt-3 py-3 uppercase fantasy text-2xl -mb-24 text-green-700' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Tipos de Productos</button>
                </div>
            </section>

            <div className="w-full py-20  bg-center bg-black h-full">
                <div className="grid grid-cols-2 mt-2  items-center justify-center">

                    <button className="bg-green-800 text-white text-center  text-xl   py-4 px-10 sm:py-2 rounded-lg mx-auto" style={{ fontFamily: 'Centaur,cursiva' }}
                    onClick={() => scrollToSection('flores')}>
                      Flores
                    </button>

                    < button className="bg-green-800 text-white text-center  text-xl   py-4 px-8 sm:py-2 rounded-lg mx-auto" style={{ fontFamily: 'Centaur,cursiva' }}  onClick={() => scrollToSection('extracto')}>
                      Extracto
                    </button>



                </div>
               
                <div className="grid grid-cols-2 mt-10  items-center justify-center">

                    <button className="bg-green-800 text-white text-center  text-xl   py-4 px-8 sm:py-2 rounded-lg mx-auto" style={{ fontFamily: 'Centaur,cursiva' }}  onClick={() => scrollToSection('terpeno')}> Terpeno</button>

                    <button className="bg-green-800 text-white text-center  text-xl   py-4 px-6 sm:py-2 rounded-lg mx-auto" style={{ fontFamily: 'Centaur,cursiva' }}  onClick={() => scrollToSection('comestible')}> Comestible</button>
                </div>

                <div className="flex items-center justify-center mx-4 py-5">
                    <div>

                        <button className="bg-green-800 text-white text-center text-2xl py-2 px-8 sm:py-2 rounded-lg" style={{ fontFamily: 'Centaur, cursiva' }}  onClick={() => scrollToSection('metodo')}>  Métodos y formas de consumo responsable </button>
                    </div>
                </div>

                <div className="flex items-center justify-center mx-10 py-2">
                    <div>

                        <button className="bg-green-800 text-white uppercase text-center text-xl py-2 px-36 sm:py-2 rounded-lg" style={{ fontFamily: 'Centaur, cursiva' }}  onClick={() => scrollToSection('hongo')}> Psilocibina
                            <span className='block text-white text-lg '>(Hongo medicinal)</span>
                        
                        </button>

                    </div>
                </div>
              
               
            
            </div>
            <ProductSection/>
        </div>
    );
}

export default Products;