import { React } from 'react';
import Card from './Card';
import { Link } from 'react-router-dom';


function Section() {
  
  return (
    <>
      < div className="top-4 w-full  bg-center bg-cover h-auto" style={{ backgroundImage: `url('/assets/fondo.png')` }} >
        <div className="container relative mx-auto">
          <div className="flex items-center justify-center">
            <div className="mt-12">
              <p className="block font-sans text-green-600   uppercase font-normal text-center text-xs sm:text-2xl  mt-5 " style={{ fontFamily: 'STIX Two Math , cursiva' }}>
                El cannabis, también conocido como marihuana,
              </p>
              <p className='block font-sans text-green-600  text-center uppercase font-normal  text-xs sm:text-2xl w-full' style={{ fontFamily: 'STIX Two Math , cursiva' }}> es una planta que contiene sustancias químicas llamadas.</p>
            </div>
          </div>

        </div>
        <button className='text-white bg-green-600 w-full py-2 mt-5 font-normal  uppercase text-3xl' style={{ fontFamily: 'STIX Two Math ,cursiva' }}
        > <Link to="/cann#cannabinoides">Cannabinoides</Link>

        </button>
        <h1 className="text-green-600 uppercase text-center text-2xl mt-5 mb-5" style={{ fontFamily: 'STIX Two Math,cursiva' }}>Incluye</h1>

        <div className='grid grid-cols-2 gap-6 justify-center'>
          <div className='ml-4 flex justify-center items-center'>
            <button className='bg-green-600 text-white font-normal text-xl sm:text-2xl rounded py-1 px-12 sm:px-16  inline-block' style={{ fontFamily: 'STIX Two Math,cursiva' }} >
              <Link to="/cann#cbd"> CBD
                <span className=' block text-center text-white uppercase text-xs sm:text-sm'>cannabidiol</span>
              </Link>
            </button>
          </div>
          <div className='flex items-center justify-center mr-4'>
            <button className='bg-green-600 text-white font-normal  text-xl sm:text-2xl rounded py-1 px-3 sm:px-6  inline-block' style={{ fontFamily: 'STIX Two Math,cursiva' }}
      >
              <Link to="/cann#thc">   THC
                <span className='block text-center text-white uppercase  text-xs sm:text-sm'>tetrahidrocannabinol</span>
              </Link>
            </button>
          </div>
        </div>
        <div className='py-4 text-center justify-center'>
          <h2 className='text-green-800 uppercase fantasy font-bold text-2xl' style={{ fontFamily: 'Antonio Regular,cursiva' }}>Sativa /Indica/ Ruderalis
          </h2>
          <span className='uppercase text-green-800 text-sm sm:text-xl font-normal fantasy ' style={{ fontFamily: 'STIX To Math,cursiva' }}>Son las tres subespecies de la planta cannabis</span>
        </div>

        <Card />

      </div>

    </>


  );
}
export default Section;