import React from 'react';

function Card() {
    return (
        <>
            <div className="relative mb-10 ">
                <div className="flex flex-wrap mt-5  gap-0">
                    <div className="relative flex items-center justify-center  w-1/2">

                        <div className="flex flex-col items-center h-auto mx-4 pb-6 ">
                            <div className='rounded-full h-24 w-24 mb-2 bg-green-800 flex items-center justify-center'>
                                <img src="/assets/img-2.png" className="rounded-full w-20 h-20  mb-4" alt="Imagen 2" />
                            </div>
                            <p className="text-center uppercase text-sm sm:text-xl font-bold text-green-700" style={{ fontFamily: 'STIX Two Math,cursiva' }}>Sativa</p>
                            <p className='block text-center  uppercase placeholder:text-sm sm:text-lg text-green-800' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Es conocida por su aspecto alto y esbelto, hojas delgadas y alargadas y sus flores son más alargadas que las de las variedades indica</p>

                        
                        </div>
                    </div>
                    <div className="relative flex items-center justify-center  w-1/2">

                        <div className="flex flex-col items-center h-auto mx-4 mb-20 md:mb-0 lg:mb-0">
                            <div className='rounded-full h-24 w-24 mb-2  bg-green-800 flex items-center justify-center'>
                                <img src="/assets/img-1.png" className="rounded-full w-20 h-20  mb-4" alt="Imagen 1" />
                            </div>

                            <p className="text-center uppercase text-sm sm:text-xl font-bold text-green-700" style={{ fontFamily: 'STIX Two Math,cursiva' }}>Indica</p>

                            <p className='block  text-center uppercase placeholder:text-xs  sm:text-xl text-green-800' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Se caracteriza por ser una planta más baja y robusta.Con hojas anchas y cortas y densas flores de coollos </p>
                          </div>
                    </div>
                    <div className="absolute inset-y-0 left-1/2 transform -translate-x-1/2 h-auto bg-gray-400 w-0.5"></div>
                </div>

            </div>


        </>
    );
}
export default Card;