import React from 'react';
import Section from './Section'
import Footer from './Footer'
import FlotantButton from './FlotantButton';
import Navbar from './Navbar'
function Home() {
    return (

<>
<Navbar/>
        <section className="relative ">
            <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
                <div

                    className="absolute top-0 w-full h-full bg-center "



                >
                    < video className="w-full h-full object-cover home-video" autoPlay loop muted>
                        <source src="assets/video.mp4" type="video/mp4" />
                        Tu navegador no soporta la etiqueta de video.
                    </video>
                  <Section/>
                </div>
                <div className="container relative mx-auto">
                    <div className="items-center flex flex-wrap">
                        <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                            <div className="pr-12">
                                <h1 className="text-white italic font-normal text-2xl" style={{ fontFamily: 'STIX Two Math,cursiva' }}>
                                    ¡La información es la llave que abre las puertas de la comprensión!
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute bottom-0  w-full text-center mb-2">
                    <img src="assets/LOGO.png" className="w-40 h-15 mx-auto -mb-24" alt="Imagen" />
                </div>
                <FlotantButton/>
               
<Footer/>
            </div>
         
        </section>
     
        </>
    );
}
export default Home;