import React from 'react';
import FlotantButton from './FlotantButton';
import Footer from './Footer';


function ProductSection() {

    return (
        <>
            <div className='text-center text-3xl text-white' style={{ fontFamily: 'Centaur,cursiva' }}><h1 id="flores">Flores
            </h1></div>
            <div className=' bg-green-700 opacity-75 container w-11/12 sm:w-3/4 mx-auto mt-4 rounded-lg'>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> Las flores de cannabis son las partes de la planta de cannabis que se cosechan y se utilizan con fines médicos y recreativos.Estas flores contienen una variedad de cannabinoides, incluyendo  THC Y CBD, así como otros componentes como terpenos y flavonoides que dan al cannabis su aroma y sabor característicos. Las flores de cannabis se consuen de diferentes maneras,incluyendo fumar,vaporizar,comer o beber en forma de té.El consumo de flores de cannabis puede tener efectos tanto positivos como negativos en la salud física y mental de las personas.Los efectos del cannabis varían según la cepa y la cantidad consumida, así como laforma en que se consume.Las flores de cannabis se han utilizado tradicionalmente para aliviar una variedad de síntomas, incluyendo dolor crónico, la inflamación,la ansiedad, el insomnio y las náuseas, entre otros.También se utilizan en la producción de aceites, tinturas y otros productos de cannabis que se utilizan para tratar una amplia gama de condiciones médicas.</h2>

            </div>
            <div className='grid grid-cols-2 mt-10'>
                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full  bg-green-800 flex items-center justify-center'>
                            <img src="/assets/flores1.jpg" className="rounded-full w-40 h-40 " alt="Imagen 1" />
                        </div>
                    </div>


                </div>

                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full bg-gray-100 flex items-center justify-center'>
                            <img src="/assets/flores2.png" className="rounded-full w-40 h-40" alt="Imagen 2" />
                        </div>
                    </div>


                </div>
            </div>
            <div className='text-center text-3xl text-white' style={{ fontFamily: 'Centaur,cursiva' }}><h1 id='extracto'>Extractos
            </h1></div>
            <div className=' bg-green-700 opacity-75 container w-11/12 sm:w-3/4 mx-auto mt-4 rounded-lg'>

                < h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> Los extractos de cannabis son concentrados de cannabinoides y terpenos que se extraen  de la planta de cannabis utilizando diferentes métodos de extracción. Estos métodos incluyen  la extracción con solventes como: butano , propano, etanol o CO2, así como métodos sin solventes como la extracción con hielo seco y agua. Los extractos de cannabis suelen ser mucho más potentes que las flores de cannabis tradicionales, ya que contienen niveles más altos de cannabinoides como THC Y CBD, así como una variedad de otros cannabinoides y terpenos que se encuentran en la planta. Los extractos de cannabis pueden tener muchas formas diferentes,incluyendo ceras, aceites,shatter, budder y más.</h2>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Los consumidores pueden inhalarlos usando una pipa un vaporizador o dab rig, se pueden usar para hacer comestibles o tinturas. Los extractos de cannabis son populares entre los consumidores que buscan una experiencia más potente y efectiva que la que se obtiene con el consumo de flores de cannabis tradicionales.Sin embargo, se debe tener precaución al consumir extractos de cannabis debido a su alta potencia y a la posibilidad.</h2>

            </div>
            <div className='grid grid-cols-2 mt-10'>
                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full bg-gray-100 flex items-center justify-center'>
                            <img src="/assets/extracto1.png" className="rounded-full w-40 h-40 " alt="Imagen 2" />
                        </div>
                    </div>


                </div>

                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full  bg-gray-100 flex items-center justify-center'>
                            <img src="/assets/extractos2.png" className="rounded-full w-40 h-40 " alt="Imagen 2" />
                        </div>
                    </div>


                </div>
            </div>

            <div className='text-center text-3xl text-white' style={{ fontFamily: 'Centaur,cursiva' }}><h1 id='comestible'>Comestibles
            </h1></div>
            <div className=' bg-green-700 opacity-75 container w-11/12 sm:w-3/4 mx-auto mt-4 rounded-lg'>

                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Los comestibles de cannabis son productos alimenticios que contienen tetrahidrocannabinol(THC),el principal compuesto psicoactivo del cannabis.Estos productos se hacen al infundir aceite de cannabis en alimentos y bebidas como galletas, brownies, caramelos, chocolates, té y bebidas energéticas, entre otros.</h2>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Cuando se consume un comestible de cannabis, el THC  es metabolizado por el hígado y convertido en una forma más potente de THC llamada 11-hidroxi-THC.Esta forma de THC puede producir efectos más intensos y duraderos que los que se experimentan al fumar cannabis.</h2>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Es importante tener en cuenta que los comestibles de cannabis pueden ser muy potentes  es difícil controlar la cantidad de THC  que se consume en un solo bocado o trago. Además, los efectos pueden tardar más en aparecer  más en aparecer (hasta una hora o más) y durar más tiempo(hasta varias horas), lo que puede llevar a sobredosis accidentales efectos secundarios no deseados.</h2>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Por esta razón, es importante usar los comestibles de cannabis con precaución y  bajo la supervisión de un profesional de la salud cpacitado en el tema.</h2>

            </div>

            <div className='grid grid-cols-2 mt-10'>
                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full  bg-gray-100 flex items-center justify-center'>
                            <img src="/assets/comestibles1.jpeg" className="rounded-full w-40 h-40 " alt="Imagen 2" />
                        </div>
                    </div>


                </div>

                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full  bg-gray-100 flex items-center justify-center'>
                            <img src="/assets/comestibles2.png" className="rounded-full w-40 h-40 " alt="Imagen 2" />
                        </div>
                    </div>


                </div>
            </div>
            <div className='text-center text-3xl text-white' style={{ fontFamily: 'Centaur,cursiva' }}><h1 id='terpeno'>Terpeno
            </h1></div>
            <div className=' bg-green-700 opacity-75 container w-11/12 sm:w-3/4 mx-auto mt-4 rounded-lg'>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> Los terpenos son compuestos orgánicos aromáticos que se encuentran en muchas plantas,incluyendoel cannabis.Son responsables del amor y sabor distintivos de muchas variedades de cannabis,así como de otras plantas. Además de su papel en  la aromatización de las plantas, los terpenos también pueden tener propiedades terapéuticas.</h2>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> Se cree  que algunos terpenos tienen propiedas antiinflamatorias, analgésicas, sedantes y ansiolíticas, entre otras.</h2>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Estos efectos se deben a que los terpenos pueden interactuar con otros compuestos en el cannabis, como los cannabinoides, para producir un efecto sinérgico conocido  como el efecto séquito.</h2>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Algunos terpenos comunes  en el cannabis incluyen el mireeno, el limoneno,el pineno, el linalool y el beta-cariofileno, entre otros.</h2>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> Cada terpeno tiene un aroma y sabor distinto y se cree que puede tener efectos terapéuticos únicos.</h2>

                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Algunos productores de cannabis y consumidores también creen que la presencia y combinación de terpenos en una variedad de cannabis puede influir en la calidad y experiencia del cannabis.</h2>

            </div>
            <div className='grid grid-cols-2 mt-10'>
                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full  bg-gray-200 flex items-center justify-center'>
                            <img src="/assets/terpeno1.jpg" className="rounded-full w-40 h-40 " alt="Imagen 2" />
                        </div>
                    </div>


                </div>

                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full  bg-gray-200 flex items-center justify-center'>
                            <img src="/assets/terpeno2.jpg" className="rounded-full w-40 h-40  " alt="Imagen 2" />
                        </div>
                    </div>


                </div>
            </div>
            <div className='text-center text-3xl text-white' style={{ fontFamily: 'Centaur,cursiva' }}><h1 id='metodo'>Métodos y fomas de consumo responsable
            </h1></div>
            <div className=' bg-green-700 opacity-75 container w-11/12 sm:w-3/4 mx-auto mt-4 rounded-lg'>

                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> Existen distintas formas y métodos de consumir productos de cannabis.Entre ellos el más popular ¨Porros,Barreto,Philli, Pot,etc¨</h2>
            </div>

            <div className="flex flex-col items-center mt-10">
                <div className='rounded-full bg-gray-100 flex items-center justify-center'>
                    <img src="/assets/porro.png" className="rounded-full w-40 h-40 " alt="Imagen 2" />
                </div>
            </div>
            <div id='consumo' className=' bg-green-700 opacity-75 container w-11/12 sm:w-3/4 mx-auto mt-4 rounded-lg'>

                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> Los consumidores prefieren utilizar tipos de papel Orgánico entre los cuales se encuentran marcas como: Blazy Susan,OCB ,RAW,entre muchas otras.</h2>
            </div>
            <div className='grid grid-cols-3 mt-10'>
                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full bg-gray-100 flex items-center justify-center mx-1'>
                            <img src="/assets/papel1.png" className="rounded-full w-40 h-40 " alt="Imagen 2" />
                        </div>
                    </div>


                </div>

                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full  bg-gray-100 flex items-center justify-center mx-1'>
                            <img src="/assets/papel2.png" className="rounded-full w-40 h-40 " alt="Imagen 2" />
                        </div>
                    </div>


                </div>

                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full  bg-gray-100 flex items-center justify-center mx-1'>
                            <img src="/assets/papel3.png" className="rounded-full w-40 h-40 " alt="Imagen 2" />
                        </div>
                    </div>


                </div>
            </div>
            <div id='consumextracto' className=' bg-green-700 opacity-75 container w-11/12 sm:w-3/4 mx-auto mt-4 rounded-lg'>

                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Para algunos con mayor experiencia prefieren extractos los cuales concentran mayor cantidad de cannabinoides, para estos extractos se utilizan herramientas específicas por ejemplo:</h2>
            </div>

            <div className='grid grid-cols-3 mt-10'>
                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full bg-gray-100 flex items-center justify-center mx-1'>
                            <img src="/assets/herr1.png" className="rounded-full w-40 h-40 " alt="Imagen 2" />
                        </div>
                    </div>


                </div>

                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full  bg-gray-100 flex items-center justify-center mx-1'>
                            <img src="/assets/herr2.png" className="rounded-full w-40 h-40 " alt="Imagen 2" />
                        </div>
                    </div>


                </div>

                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full  bg-gray-100 flex items-center justify-center mx-1'>
                            <img src="/assets/herr3.png" className="rounded-full w-40 h-40 " alt="Imagen 2" />
                        </div>
                    </div>


                </div>
            </div>

            <div className='text-center mt-10 text-3xl text-white' style={{ fontFamily: 'Centaur,cursiva' }}><h1 id="hongo">Hongo Medicinal
            </h1></div>
            <div className=' bg-green-700 opacity-75 container w-11/12 sm:w-3/4 mx-auto mt-4 rounded-lg'>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>La psilocibina es una sustancia psicodélica que se encuentra naturalmente en algunos hongos,también conocidos como¨hongos mágicos¨ o ¨setas alucinógenas¨. Esta sustancia es responsable de los efectos  psicodélicos que se experimentan después de consumir estos hongos.Cuando se consume psilocibina, se produce una alteración en la percepción, el pensamiento y las emociones.Los efectos pueden incluir alucinaciones visuales, cambios en el estado de ánimo y sentimientos de euforia o ansiedad. </h2>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Es importante tener en cuenta que la psiloacibina es una sustancia controlada es una sustancia controlada en muchos países, y su uso puede ser ilegal y peligroso si no se maneja con cuidado y precaución.Además, el uso de psiloacibina puede tener efectos negativos en algunas personas,especialmente si tienen problemas de salud mental preexistentes</h2>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Consumo Responsable (Productos)</h2>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Deshidratados</h2>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Comestibles</h2>
                <h2 className='text-white text-2xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Micro Dosis</h2>
            </div>

            <div className='bg-black grid grid-cols-3 mt-5 mb-5'>
                <div className=" bg-black relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full bg-gray-200 flex items-center justify-center mx-1'>
                            <img src="/assets/hongo1.png" className="rounded-full w-40 h-40 " alt="Imagen 1" />
                        </div>
                    </div>


                </div>

                <div className="relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full  bg-gray-200 flex items-center justify-center mx-1'>
                            <img src="/assets/hongo2.png" className="rounded-full w-40 h-40 " alt="Imagen 2" />
                        </div>
                    </div>


                </div>

                <div className=" bg-black relative flex items-center justify-center">

                    <div className="flex flex-col items-center">
                        <div className='rounded-full  bg-gray-200 flex items-center justify-center mx-1 '>
                            <img src="/assets/hongo3.png" className="rounded-full w-40 h-40 " alt="Imagen 3" />
                        </div>

                    </div>

                </div>
                <div className='bg-black mt-20'>   </div>

            </div>
            <FlotantButton />

            <Footer />

        </>
    )
}
export default ProductSection;