import { React } from 'react';

function CannSection() {
    return (
        <>

            <div className="top-4 w-full  bg-center bg-cover h-full" style={{ backgroundImage: `url('/assets/fondo.png')` }}>
                <div id="cbd" className='flex items-center justify-center'>

                    <button className='bg-green-600 mt-10 text-white text-2xl px-16 rounded-lg py-2' style={{ fontFamily: 'STIX Two Math,cursiva' }} >CBD
                        <span className='block text-white text-xl '>cannabidiol</span>
                    </button>

                </div>
                <div className=' bg-green-700 opacity-50 container w-11/12 sm:w-3/4 mx-auto mt-4 rounded-lg'>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> El CBD (cannabidiol) es uno de los muchos </h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> compuestos químicos presentes en la planta de cannabis.</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> Es uno de los  cannabionoides no psicoactivos más conocidos </h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> y estudiados junto con el THC.</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> A diferencia del THC, que es el compuesto que produce </h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> la sensación de "colocón",</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> el CBD no tiene efectos psicoativos significativos.</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> El CBD ha sido objeto de investigación debido</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> a sus posibles efectos terapéuticos </h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>en una variedad de condiciones médicas,</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>como la epilepsia, la ansiedad,el dolor crónico,</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>entre otros.</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2 pt-4  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>Además, ha ganado popularidad como</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>suplemento nutricional y se ha utilizado en</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2 pb-5  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>produtos como aceites,cremas y gomitas.</h2>

                </div>

                <div id="thc" className='flex items-center justify-center'>

                    <button className='bg-green-600 mt-10 text-white text-2xl px-8 rounded-lg py-2' style={{ fontFamily: 'STIX Two Math,cursiva' }}>THC
                        <span className='block text-white text-xl'>tetrahidrocannabinol</span>
                    </button>

                </div>
                <div className=' bg-green-700 opacity-50 pb-5 container w-11/12 sm:w-3/4 mx-auto mt-4 mb-2  rounded-lg'>

                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> El THC (tetrahidrocannabinol) es el principal </h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> compuestos psicoactivo presente en la planta de cannabis</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> Es lo que produce los efectos de alteración mental </h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> y euforia asociados con el consumo de marihuana.</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> El THC se une a los receptores cannabinoides  </h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> en el cerebro y el cuerpo, lo que resulta una variedad</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> de efectos fisiológicos y psicológicos.</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2 pt-4 text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> En el nivel de THC presente en una cepa de cannabis</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>puede variar significativamente, </h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}> y afecta la potencia y los efectos</h2>
                    <h2 className='text-white text-xl sm:text-2xl font-normal mx-2  text-center' style={{ fontFamily: 'STIX Two Math,cursiva' }}>del producto final.</h2>

                    <div className='mb-10'></div>

                </div>

            </div>


        </>
    );
}
export default CannSection;