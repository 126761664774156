
import Navigation from './components/Navigation'
import Home from './components/Home'
import Cann from './components/Cann'
import { Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga';
import Products from './components/Products'



function App() {

  ReactGA.initialize('G-CDR4306FC8'); // Reemplaza 'G-CDR4306FC8' con tu Measurement ID real

  ReactGA.pageview(window.location.pathname + window.location.search);


  return (
 <div>
      <Route
        render={({ location }) =>
          location.pathname !== '/' && <Navigation />
        }
      />

      <Switch>
        <Route exact path="/" component={Home} />

        <Route path="/cann" component={Cann} />
      

        <Route path="/products" component={Products} />

      </Switch>




      </div>
  
  );
}

export default App;
