import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
    return (
        <header >
            <nav class="relative bg-white py-1">
                <div class="flex items-center justify-start py-2 mx-2">

                    <Link to="/" className="my-2 text-green-600 text-sm mx-5 lg:text-xl sm:text-xl hover:text-green-800 dark:hover:text-green-800 md:mx-4 md:my-0" style={{ fontFamily: 'STIX Two Math, sans-serif' }}>INICIO</Link>
                    <Link to="/cann" className="my-2 text-green-600 text-sm mx-5 lg:text-xl sm:text-xl hover:text-green-800 dark:hover:text-green-800 md:mx-4 md:my-0" style={{ fontFamily: 'STIX Two Math, sans-serif' }}>CANNABINOIDES</Link>
                    <Link to="/products" className="text-green-600 ml-3 text-sm sm:text-xl lg:text-xl hover:text-green-800 dark:hover:text-green-800" style={{ fontFamily: 'STIX Two Math, sans-serif' }}>PRODUCTOS</Link>





                </div>






            </nav>

        </header>

    );
};

export default Navigation;