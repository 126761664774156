import { React} from 'react';
import CannSection from './CannSection';
import FlotantButton from './FlotantButton'
import Footer from './Footer'

function Cann() {
  function scrollToElement(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  
  function handleScrollToElement() {
    const hash = window.location.hash;
    if (hash.includes('cbd')) {
      scrollToElement('cbd');
    } else if (hash.includes('thc')) {
      scrollToElement('thc');
    }

    else if (hash.includes('cannabinoides')) {
      scrollToElement('cannabinoides');
    }
  }
  
  document.addEventListener('DOMContentLoaded', handleScrollToElement); // Llamar a handleScrollToElement al cargar la página
  
  window.addEventListener('hashchange', handleScrollToElement); // Llamar a handleScrollToElement cuando cambie el hash de la URL
  return (
    <>
      <section className="relative">
        <div className="relative pt-48 pb-48 flex content-center items-center justify-end min-h-screen-75">
          <div
            class="absolute top-0 w-full h-full bg-center bg-cover"
            style={{ backgroundImage: `url('/assets/pag-2.jpg')` }}
          >   </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">

                <div className="absolute right-5 top-1/2 transform -translate-y-1/2">
                  <img
                    src="assets/LOGO.png"
                    className="w-40 h-15 mt-5"
                    alt="Imagen"
                  />

                </div>
              </div>
            </div>
          </div>

          <div

            className="absolute inset-0 mt-64 -mb-5 flex items-center justify-center"
          >
            <button id="cannabinoides"
              className="bg-white rounded-lg px-10 mt-3 py-3 fantasy text-2xl -mb-24 text-green-700"
              style={{ fontFamily: 'STIX Two Math, cursiva' }}
            >
              CANNABINOIDES
            </button>
          </div>



        </div>


      </section>
      <CannSection />
      <FlotantButton />

      <Footer />

    </>
  );
}

export default Cann;